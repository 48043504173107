<template>
	<v-container>
		<div>
			<div class="text-center">
				<img
					alt="Abismo Studios logo white"
					src="../../assets/img/logo_white.png"
					class="logo"
				/>
			</div>
			<h2 class="text-center my-10">
				Abismo Studios is a group of brazilian content creators that include streamers, writers and game developers, that came together to hone their abilities and broaden their horizons.
			</h2>
			<v-divider />
			<div class="mt-10">
				<div v-for="(row, i) in rows" :key="i" >
					<v-row>
						<v-col v-for="(item, index) in row" :key="index" cols="12" md="4" sm="6">
							<v-card rounded>
								<h3 class="text-center py-4">{{ item.name }}</h3>
								<v-img :lazy-src="item.lazy" :src="item.img" height="280" />
								<h4 class="text-center pt-4 mb-0 pb-0">{{ item.title }}</h4>
								<div class="text-center pa-4 text-sm-body-2" v-html="item.description"></div>
								<v-divider />
								<div class="text-center py-1">
									<v-btn 
										v-for="(link, num) in item.link" 
										:target="item.target || '_blank'" 
										:key="num" 
										icon 
										:href="link.href"
									>
										<v-icon>{{ link.icon }}</v-icon>
									</v-btn>
								</div>
							</v-card>
						</v-col>
					</v-row>
					<v-divider v-if="rows.length > 0 && i != (rows.length - 1)" class="my-10"/>
				</div>
			</div>
		</div>
	</v-container>
</template>

<script>
export default {
	name: "AboutPage",
	data()
	{
		return {
			rows: [
				[
					{
						name: "Gabriel \"Morto\" Senna",
						title: "Programmer",
						img: require("../../assets/img/about/morto.jpg"),
						lazy: require("../../assets/img/about/morto.jpg"),
						description: `It is not the programming language that defines the programmer, but his ability to use them!`,
						link: [
							{
								href: "https://github.com/MortoMS",
								icon: "mdi-github"
							},
							{
								href: "https://codepen.io/morto",
								icon: "mdi-codepen"
							},
							{
								href: "mailto:msgabrielrafael@gmail.com",
								icon: "mdi-email-edit"
							}
						]
					},
					{
						name: "Nícolas \"Ksaa\" Lúcio",
						title: "Founder & Director",
						img: require("../../assets/img/about/ksaa.jpg"),
						lazy: require("../../assets/img/about/ksaa.jpg"),
						description: `Only memories speak the truth.<br><br>`,
						link: [
							{
								href: "https://github.com/NicolasLucio",
								icon: "mdi-github"
							},
							{
								href: "https://www.artstation.com/nicolaslucio",
								icon: "mdi-artstation"
							},
							{
								href: "https://ksaa.itch.io/",
								icon: "mdi-gamepad-variant"
							},
							{
								href: "https://steamcommunity.com/id/ksaa/",
								icon: "mdi-steam"
							},
							{
								href: "mailto:nicolasslucio@gmail.com",
								icon: "mdi-email-edit"
							}
						]
					},
					{
						name: "Cleyton Verde",
						title: "3D Artist",
						img: require("../../assets/img/about/cleyton.png"),
						lazy: require("../../assets/img/about/cleyton.png"),
						description: `I'm a chair.<br><br>`,
						link: [
							{
								href: "https://github.com/CleytonVerde",
								icon: "mdi-github"
							},
							{
								href: "https://www.artstation.com/cleytonemanoel",
								icon: "mdi-artstation"
							},
						]
					},
					{
						name: "Almir David",
						title: "Marketing, Producer & Writer",
						img: require("../../assets/img/about/almir.jpg"),
						lazy: require("../../assets/img/about/almir.jpg"),
						description: `I will change this later.`,
						link: [
							{
								href: "mailto:almirbdavid@hotmail.com",
								icon: "mdi-email-edit", 
							},
						]
					},
					{
						name: "Topeira Azul",
						title: "Modeler",
						img: require("../../assets/img/about/topeira.jpg"),
						lazy: require("../../assets/img/about/topeira.jpg"),
						description: `I hate China.`,
						link: [
							{
								href: "https://github.com/VictorHugoPossentt",
								icon: "mdi-github"
							},
							{
								href: "https://www.artstation.com/",
								icon: "mdi-artstation"
							}
						]
					},
					{
						name: "Ciel",
						title: "Creative Director",
						img: require("../../assets/img/about/lucasv.jpg"),
						lazy: require("../../assets/img/about/lucasv.jpg"),
						description: `I'm productive, sometimes.<br><br>`,
						link: [
							{
								href: "https://github.com/CielCake",
								icon: "mdi-github"
							},
							{
								href: "https://cielcake.itch.io/",
								icon: "mdi-gamepad-variant"
							}
						]
					},
					{
						name: "João Pedro Cantador",
						title: "Producer",
						img: require("../../assets/img/about/jpcantador.jpg"),
						lazy: require("../../assets/img/about/jpcantador.jpg"),
						description: `God punished me twice when he made me play console games on Nintendo.`,
						link: [
							{
								href: "https://github.com/jpcantador",
								icon: "mdi-github"
							},
							{
								href: "https://itch.io/profile/jpcantador",
								icon: "mdi-gamepad-variant"
							}
						]
					}
				],
				[
					{
						name: "Nowah-Ru",
						title: "Streamer",
						img: require("../../assets/img/about/nowah.png"),
						lazy: require("../../assets/img/about/nowah_lazy.jpg"),
						description: `Bear.`,
						link: [
							{
								href: "https://www.twitch.tv/nowahru",
								icon: "mdi-twitch"
							},
							{
								href: "https://twitter.com/Nowah_Ru/",
								icon: "mdi-twitter"
							},
							{
								href: "https://www.artstation.com/nowahru",
								icon: "mdi-artstation"
							}
						]
					},
					{
						name: "Aoki Iara",
						title: "Streamer",
						img: require("../../assets/img/about/iara.png"),
						lazy: require("../../assets/img/about/iara_lazy.jpg"),
						description: `Luck Cat.`,
						link: [
							{
								href: "https://www.twitch.tv/aoki_iara",
								icon: "mdi-twitch"
							},
							{
								href: "https://twitter.com/AokiIara",
								icon: "mdi-twitter"
							}
						]
					},
					{
						name: "DiavoLeon",
						title: "Streamer",
						img: require("../../assets/img/about/leo.png"),
						lazy: require("../../assets/img/about/leo_lazy.jpg"),
						description: `Vampire.`,
						link: [
							{
								href: "https://www.twitch.tv/diavoleon",
								icon: "mdi-twitch"
							},
							{
								href: "https://twitter.com/diavo_leon",
								icon: "mdi-twitter"
							}
						]
					}
				]
			]
		}
	}
};
</script>

<style scoped>
.container > div {
	max-width: 1000px;
	margin: 5em auto;
}

.text-sm-body-2 {
	height: 92px;
}

.logo {
	width: 40%;
}
</style>